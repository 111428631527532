@media screen and (max-width: 1300px) {
	.trademark__first, .trademark__second, .trademark__third {
		margin-left: 0;
		margin-right: 0;
		top: 0;
	}
	#trademark {
		height: auto;
		margin-bottom: 80px;
	}
}

@media screen and (max-width: 1169px) {
	.container {
        width: 970px;
    }
    .trademark__first, .trademark__second, .trademark__third {
    	width: 100%;
    }
    body {
    	line-height: auto;
    }
    h1 {
    	line-height: 1.6em;
    }
    .header__form_input {
    	float: none;
    	margin-right: 0;
    	display: block;
    	margin-right: auto;
    	margin-left: auto;
    	width: 270px;
    	margin-bottom: 20px;
    }
    .header__form .cta-btn {
    	float: none;
    	margin-right: auto;
    	margin-left: auto;
    	margin-top: 10px;
    	margin-bottom: 70px;
    }
    h2 {
    	line-height: 1.3em;
    }
    .why__list {
    	width: 375px;
    	margin-left: 40px;
    }
    .reorder {
    	width: 100%;
    	margin-left: 0;
    	margin-right: 0;
    }

    .how__wrap {
    	width: 553px;
    	display: block;
    	margin-right: auto;
    	margin-left: auto;
    	float: none;
    	margin-bottom: 40px;
    }
    #how {
    	padding-bottom: 40px;
    }
    .moment__item p {
    	width: 235px;
    }
    .work__wrap_fourth {
    	margin-left: 100px;
    }
    #deliver .more__item {
    	margin-left: 55px;
    }
    #map {
    	float: none;
    	display: block;
    	margin-right: auto;
    	margin-left: auto;
    }
    .contacts__wrap_text {
    	float: none;
		display: block;
		width: 335px;
		margin-right: auto;
		margin-left: auto;
    }
    .social__wrap {
    	margin-left: 115px;
    }
    .work__plan {
    	margin-left: 0;
    }

}

@media screen and (max-width: 1010px) {
	.container	{
		width: 740px;
	}
	.logo__text {
		display: none;
	}
	#header .vertical-line {
		display: none;
	}
	.header__merit {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}
	.header__merit .point {
		width: 415px;
	}
	.profit__item {
		width: 100%;
		float: none;
	}
	.profit__description {
		padding: 0px 0 30px 0;
	}
	#profit {
		padding-bottom: 50px;
	}
	.more__item {
		width: 33%;
	}
	.why__list {
		width: 100%;
		margin-left: 100px;
	}
	.why__list_first, .why__list_second {
		float: none;
	}
	.moment__item {
		width: 100%;
	}
	.moment__main-wrap {
	    float: left;
	    margin-right: 16px;
	    width: 50px;
	    text-align: center;
	}
	.moment__item p {
	    width: 335px;
	}
	.moment__item {
		margin-bottom: 25px;
	}
	.moment__wrap {
		width: 420px;
		margin-right: auto;
		margin-left: auto;
	}
	.more__item {
		width: 50%;
	}
	#buisness .undertitle__text {
	    padding: 17px 50px;
	}
	.work__wrap {
		width: 50%;
	}
	.work__wrap_fourth, .work__wrap_fifth {
	    margin-left: 0px;
	    width: 50%;
	}
	#deliver .more__item {
		width: 100%;
		margin-left: 0;
	}
	.deliver__wrap {
	    margin-top: 70px;
	    width: 395px;
	    margin-left: auto;
	    margin-right: auto;
	}
	#deliver .reorder {
		margin-bottom: 80px;
	}
	.footer .logo-wrapper {
		margin: 15px 0;
		float: none;
	}
	.social__wrap {
		float: none;
		margin: 0;
	}
	.rigth__wrap {
		float: none;
		text-align: center;
		margin-top: 15px;
	}
}

@media screen and (max-width: 880px) {
	.trademark-window {
		width: 90%;
	}
}

@media screen and (max-width: 780px) {
	    .container {
	        width: 90%;
	    }
	    .range__item {
	    	width: 50%;
	    	margin-bottom: 65px;
	    }
	    .why__list {
	    	width: auto;
	    	margin-left: 40px;
	    }
	    .about__wrap {
		    width: 100%;
		    float: none;
		    margin-bottom: 20px;
		}
		#buisness .undertitle__text {
			padding-right: 0;
			padding-left: 0;
		}
		.work__wrap, .work__wrap_fourth, .work__wrap_fifth {
			width: 100%;
		}
		.buisness__list {
			width: auto;
		}
		.buisness__list .point {
			margin-left: 40px;
		}
		.work__plan {
			width: 330px;
			margin-right: auto;
			margin-left: auto;
		}
		.more__item p {
			padding: 10px 35px;
		}
		#header {
		 background: url(../img/bgmob.jpg) no-repeat left top/cover;
	}
}

@media screen and (max-width: 655px) {
	h1 {
		line-height: 1.4em;
		font-size: 33px;
		margin: 80px 0 15px 0;
	}
	.main-screen p {
		font-size: 25px;
	}
	.main-screen p {
	    margin-bottom: 30px;
	}
	h2 {
		font-size: 33px;
	}
	.profit__number {
		font-size: 65px;
	}
	#range .cta-btn {
		width: 100%;
	}
	#map {
		width: auto;
	}
	.more__item {
    	width: 100%;
    }
    .more__item p {
    	padding: 10px 0;
    }
}

@media screen and (max-width: 600px) {
	.how__lines {
		display: none;
	}
	#how .cta-btn {
		float: none;
		margin-right: auto;
		margin-left: auto;
	}
	.how__wrap {
		width: auto;
	}
	.how__wrap img {
		width: 100%;
	}
	 .hidden-text {
    	display: block;
    }
    .block-text {
    	display: none;
    }
    .how__milange {
    	display: none;
    }
    .how__text_second {
    	text-align: left;
    }
}

@media screen and (max-width: 500px) {
	h1 {
		font-size: 30px;
	}
	.header__line {
		width: 100%;
	}
	.main-screen p {
	    font-size: 21px;
	}
	.logo-wrapper {
		float: none;
		margin-right: 0;
	}
	.phone-wrap {
		float: none;
		text-align: center;
	}
	.logo-wrap {
		float: none;
		text-align: center;
	}
	.phone-wrap .cta-btn {
		margin-right: auto;
	}
	.header__merit .point {
	    width: 225px;
	}
	h2 {
		font-size: 30px;
	}
	#range .cta-btn {
		padding: 20px 20px;
		height: auto;
		width: auto;
		line-height: 1.35em;
	}
	h3 {
		font-size: 30px;
	}
	.moment__item p {
	    width: 205px;
	}
	.moment__wrap {
		width: 295px;
	}
	.animated-modal {
		width: 90%;
	}
}

@media screen and (max-width: 460px) {
	#deliver .more__item p {
		width: auto;
		float: none;
		text-align: center;
	}
	#deliver .more__item p:last-child {
		margin-left: 0;
	}
	#deliver .romb {
		float: none;
	}
	.deliver__wrap {
		width: auto;
	}
}

@media screen and (max-width: 430px) {
	.range__item {
	    	width: 100%;
	    	float: none;
	    }
}

@media screen and (max-width: 370px) {
	.work__wrap_text {
		width: 230px;
	}
	.work__plan {
		width: 300px;
	}
	.contacts__wrap_description p {
		width: 260px;
	}
	.contacts__wrap_text {
		width: 320px;
	}
	.rigth__wrap {
		font-size: 16px;
	}
	.rigth__wrap a {
		font-size: 18px;
	}
	.animated-modal .header__form_input {
		width: 225px;
	}
	.animated-modal .cta-btn {
		width: 250px;
	}
}

